@import '~antd/dist/antd.less';
@import './styles.less';

* {
  font-family: 'Poppins', sans-serif;
}
.full-spin {
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-sider {
  background-color: @primary-color;
}
.ant-menu-item {
  border-radius: 5px;
}
#menu-sidebar {
  .ant-menu-item:not(.ant-menu-item-selected):hover {
    background: @primary-color-hover;
  }
}

.ant-breadcrumb-separator {
  margin-top: 4px;
  font-size: 20px !important;
  color: #c5cddb !important;
}

.ant-switch-checked {
  background-color: #56c288;
}

.table-link,
.table-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.table-row-dark {
  background-color: @table-dark-row;
}

.table-row-dark:hover {
  background-color: @table-highlight-color;
}

.table-row-light {
  background-color: @table-light-row;
}

.table-row-light:hover {
  background-color: @table-highlight-color;
}

.ant-table-thead .ant-table-cell {
  background-color: @table-dark-row;
}

.ant-pagination {
  margin-top: 15px;
  display: flex;
}

.ant-pagination-total-text {
  flex: 1;
}

.icon-collapsed {
  svg {
    fill: #ffffff;
  }
}

.ant-table-thead tr th {
  font-size: 12px;
  color: @primary_black;
  font-weight: 600;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-menu-sub.ant-menu-inline {
  background-color: @primary-color;
}

.ant-badge-multiple-words {
  padding: 0 5px !important;
}

.ant-menu .ant-menu-sub .ant-menu-vertical {
  background-color: @primary-color;
}

.ant-menu-submenu-arrow {
  color: #ffffff;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: @primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 5px;
}

.ant-menu-inline .ant-menu-item::after {
  display: none;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: @primary-color !important;
}

.ant-skeleton-element {
  width: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  background-color: @primary-color;
  color: #ffffff;
  border-right: none;
}

.ant-menu-item-group-title {
  color: #748494;
  margin-top: 24px;
}

.ant-menu-item .ant-badge {
  opacity: 1 !important;
}

.ant-breadcrumb {
  display: flex;
}

.ant-breadcrumb li {
  display: flex;
}

.ant-breadcrumb,
.ant-breadcrumb span {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ant-breadcrumb div {
  color: #768499;
}

.ant-breadcrumb > span:last-child div {
  color: rgba(0, 0, 0, 0.85);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.ant-btn {
  border-radius: @border-radius-base;
}

.ant-btn-default {
  background-color: transparent;
}

.ant-btn-default:hover {
  background-color: transparent;
}

.ant-btn-default:active {
  background-color: transparent;
}

.ant-modal-body {
  padding: 0px 15px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #748494;
}

::-webkit-scrollbar-thumb:hover {
  background: #748494;
}

iframe {
  pointer-events: none;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}
.ant-pagination-item-active {
  border-color: @accent-color;
}
.ant-pagination-item-active a {
  color: @accent-color;
}
.ant-modal-header {
  text-align: center;
}
.ant-modal-title {
  font-weight: 600;
}
.ant-modal-content {
  padding: @padding-md;
}
.ant-segmented-item-label {
  font-weight: 600;
  font-size: @font-size-base;
}
.ant-segmented-item-selected {
  color: @segmented-label-color;
}
.ant-segmented {
  padding: 0px;
  border-radius: @border-radius-base;
  overflow: hidden;
}
.ant-segmented-item,
.ant-segmented-item-label {
  padding: 1px 10px;
}
.ant-segmented-item,
.ant-segmented-thumb {
  border-radius: 0px;
}
.ant-segmented-item:first-child {
  border-radius: @border-radius-base 0 0 @border-radius-base;
}
.ant-segmented-item:last-child {
  border-radius: 0 @border-radius-base @border-radius-base 0;
}
.btn-fixed {
  min-width: 100px;
}
.btn-fixed-lg {
  min-width: 150px;
}
.ant-pagination-total-text {
  color: #a9b6c1;
}
.page-size-custom-select .ant-select-selector {
  border: none !important;
}
.outer-shadow:not(.ant-select),
.outer-shadow.ant-select .ant-select-selector {
  border: 1px solid #d0d9df;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
.text-gray {
  color: #838e96;
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: transparent;
  border: 1px solid @primary-color;
}
.select-bordered.ant-select:not(.page-size-custom-select)
  .ant-select-selection-search {
  border-right: 1px solid #d0d9df;
  right: 30px !important;
}
.select-bordered .ant-select-selection-item {
  padding-right: 25px !important;
}
.ant-steps {
  max-width: 1000px;
  margin: auto;
}
.ant-steps-item-container {
  display: flex;
  align-items: center;
}
.ant-steps-item-title {
  font-size: 16px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: @disabled-bg;
  border-color: @disabled-bg;
  font-weight: bold;
  color: @disabled-color;
}
.ant-steps-item-container .ant-steps-item-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: @accent-color;
  border-color: @accent-color;
  color: #fff;
  font-weight: bold;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: @primary-color;
  font-weight: bold;
}

.ant-upload.ant-upload-drag {
  background-color: transparent;
  border-width: 2px;
  height: 300px;
}
.ant-upload-drag-icon {
  display: inline-block;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid @border-color-base;
  color: @disabled-color;
  margin: 20px 20px 40px 20px;
}
.ant-upload-preview-container {
  width: 100%;
  margin-bottom: 25px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ant-upload-preview {
  max-height: 100%;
  max-width: 100%;
}
.ant-upload-hint {
  text-align: left;
  padding: 15px 15px 0 15px;
  border-top: 1px solid @border-color-base;
}
h4.ant-typography {
  color: @primary-color;
}
.ant-btn-dangerous,
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  background-color: transparent;
}
// .ant-tabs > .ant-tabs-nav,
// .ant-tabs > div > .ant-tabs-nav {
//   display: block;
// }
// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
//   background-color: #ededed;
//   width: 100%;
//   display: block;
//   border-radius: 10px;
//   overflow: hidden;
// }
// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
//   width: 100%;
// }
// .ant-tabs-tab {
//   width: 100%;
//   text-align: center;
//   display: block;
//   font-weight: bold;
//   color: #8c8c8c;
// }
// .ant-tabs-tab.ant-tabs-tab-active {
//   background-color: @primary-color;
// }
// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: @white;
// }
// .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
// .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
//   display: none;
// }

.avatar-remax {
  aspect-ratio: 3 / 4;
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  max-width: 350px;
}
.image-preview-container {
  height: 300px;
  background-color: #f1f3f5;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 5px 0;
}

.image-preview-container .file-text {
  text-align: center;
}

.image-preview-container img {
  max-width: 100%;
  max-height: 100%;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #3587e8;
  color: @white;
  border-color: #3587e8;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: @white;
}

.ant-select {
  width: 100%;
}
.ant-space-vertical {
  width: 100%;
}
.card-user {
  box-shadow: none;

  .ant-card-body {
    padding: 24px;
  }
  .ant-card-cover img.card-user-photo {
    border-radius: 5px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}

.ant-card {
  box-shadow: @box-shadow-base;
}

.ant-form-item-label > label {
  color: #768499;
  font-size: 12;
  font-weight: 600;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  font-size: 15px;
  content: '*';
}

.ant-input {
  border-radius: 5px;
}

.btn-beside-input {
  margin-top: 46px;
}

.ql-toolbar.ql-snow {
  border-radius: 5px;
}

.report-card {
  border-radius: 5px;
  box-shadow: @box-shadow-base;
  margin-bottom: 20px;
  .ant-card-body {
    padding: 0;
  }
}

.latest-transaction-table {
  .ant-table {
    font-size: 12px;
  }
}

.report-export-modal {
  .ant-modal-header {
    text-align: left;
    padding: 0;
  }
  .ant-modal-body {
    padding: 24px 0;
  }
}

.transaction-card {
  .ant-card-body {
    padding: 0;
  }
}

.card-no-padding {
  .ant-card-body {
    padding: 0;
  }
}

.custom-modal{
  .ant-modal-content{
    padding: 0px 24px;
  }
  .ant-modal-header{
    padding: 16px 0;
    text-align: left;
  }
  .ant-modal-body{
    padding: 0;
  }
}
@height-base: 40px;@height-lg: 50px;@height-sm: 32px;@font-size-lg: 20px;@primary-color: #188007;@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #188007;@menu-highlight-color: #FFFFFF;@card-padding-base: 40px;@box-shadow-base: 0px 15px 25px -5px rgba(0, 0, 0, 0.08), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);@margin-sm: 15px;@layout-body-background: #FFFFFF;@layout-header-background: #FFFFFF;@table-header-bg: #FFFFFF;@border-color-split: #D0D9DF;@accent-color: #188007;@table-highlight-color: #C5C5C5;@table-light-row: white;@table-dark-row: #F4F6F9;@btn-default-bg: #188007;@btn-default-color: @primary-color;@btn-font-weight: 600;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-body-padding: 0 @padding-lg 0 @padding-lg;@segmented-bg: #A9B6C1;@segmented-hover-bg: #A9B6C1AA;@segmented-selected-bg: #188007;@segmented-label-color: #FFFFFF;@segmented-label-hover-color: #FFFFFF;@checkbox-border-radius: 4px;@checkbox-color: @accent-color;@radio-button-bg: transparent;@link-color: #188007;@item-hover-bg: #EEE;@primary-1: #CCC;